import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import constants from '@/utils/constants';
import modules from './modules';

Vue.use(Vuex);

const getDefaultState = () => ({
  currentUser: null,
  companies: [],
  currentCompany: null,
  errors: [],
  apiHealthStatus: constants.apiHealthStatusSuccess,
  feedbackMessage: '',
  activeDialogs: 0,
});

export default new Vuex.Store({
  modules,
  state: getDefaultState(),
  getters: {
    getUser: (state) => state.currentUser,
    errors: (state) => state.errors,
    apiHealthStatus: (state) => state.apiHealthStatus,
    companies: (state) => state.companies,
    hasCompany: (state) => state.companies && state.companies.length > 0,
    firstCompany: (state, getters) => (getters.hasCompany ? state.companies[0] : undefined),
    companiesSortedByName: (state) =>
      [...state.companies].sort((com1, com2) =>
        com1.name.toLowerCase().localeCompare(com2.name.toLowerCase(), 'no-NO'),
      ),
    getCompanyById: (state) => (companyId) => state.companies.find((c) => c.id === companyId),
    getCompanyByNameSlug: (state, getters) => (nameSlug) => {
      const companyId = getters.getCompanyIdByNameSlug(nameSlug);

      return state.companies.find((c) => c.id === companyId);
    },
    getCompanyNameSlugById: (state) => (companyId) => state.companies.find((c) => c.id === companyId)?.nameSlug,
    getCompanyIdByNameSlug: (state) => (nameSlug) => state.companies.find((c) => c.nameSlug === nameSlug)?.id,
    getFirstCompanyId: (state, getters) => getters.firstCompany?.id,
    getFirstCompanyNameSlug: (state, getters) => getters.getCompanyNameSlugById(getters.firstCompany?.id),
    feedbackMessage: (state) => state.feedbackMessage,
    selectedCompany: (state, getters) => {
      if (state.currentCompany) {
        return state.currentCompany;
      } else {
        const companyNameSlug = router.currentRoute.params.companyNameSlug || getters.getFirstCompanyNameSlug;
        let id = getters.getCompanyIdByNameSlug(companyNameSlug);
        if (!id) {
          id = state.companies.find((company) => company.nameSlug === companyNameSlug)?.id;
        }
        return getters.getCompanyById(id) || {};
      }
    },
  },
  mutations: {
    set(state, payload) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    setArrayIndex(state, payload) {
      Object.keys(payload).forEach((key) => {
        Vue.set(state[key], payload[key].index, payload[key].object);
      });
    },
    setActiveDialogs(state, payload) {
      state.activeDialogs = payload;
    },
  },
  actions: {
    async getUserAndCompanies({ dispatch }) {
      await dispatch('user/getUser');
      await dispatch('company/getCompanies');
    },
    async setCurrentCompany({ commit, dispatch }, company) {
      commit('set', { currentCompany: company });
      await dispatch('company/fetchCompanyData', { companyId: company.id }, { root: true });
    },
    set({ commit }, payload) {
      commit('set', payload);
    },
    resetToDefault({ commit, dispatch }) {
      commit('set', getDefaultState());

      for (const [name, module] of Object.entries(modules)) {
        if (module.actions?.resetState) {
          dispatch(`${name}/resetState`, null, { root: true });
        }
      }
    },
    updateCompanyState({ state, commit }, payload) {
      if (payload) {
        commit('setArrayIndex', {
          companies: {
            index: state.companies.findIndex((company) => company.id === payload.id),
            object: payload,
          },
        });
      } else {
        console.warn('no payload provided', payload);
      }
    },
    toggleDialog({ state, commit }, payload) {
      commit('setActiveDialogs', state.activeDialogs + (payload ? 1 : -1));
    },
  },
});
